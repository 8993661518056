import React from 'react'
import { CustomSection } from './style'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IInvistaQuandoQuiser {
  onOpenModal: () => void;
}

const InvistaQuandoQuiser = ({ onOpenModal }: IInvistaQuandoQuiser) => {
  const windowWidth = useWidth(200)
  const isMobile = windowWidth < WIDTH_MD
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://inter-co.onelink.me/Qyu7/3ugrv1c3'

  return (
    <CustomSection>
      <div className='container py-5'>
        <div className='row justify-content-end'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-grayscale-500 mb-0 pb-3'>Invista quando quiser!</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 pb-3'>Com o novo modelo de aporte esporádico você pode investir em seu plano quando quiser e aumentar sua rentabilidade. </p>
            {isMobile
              ? <a
                  href='https://inter-co.onelink.me/Qyu7/3ugrv1c3'
                  className='btn btn--lg bg-orange--extra my-2 text-white rounded-3 text-none fw-700 fs-14 lh-17'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      element_action: 'click button',
                      element_name: 'Trazer minha previdência para o Inter',
                      section_name: 'Invista quando quiser!',
                      redirect_url: link,
                    })
                  }}
                >
                Trazer minha previdência para o Inter
                </a>
            : <button
                className='btn btn--lg bg-orange--extra my-2 text-white rounded-3 text-none fw-700 fs-14 lh-17'
                onClick={() => {
                  onOpenModal()
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    element_action: 'click button',
                    element_name: 'Fazer um aporte',
                    section_name: 'Invista quando quiser!',
                  })
                }}
              >
              Fazer um aporte
              </button>
            }
          </div>
        </div>
      </div>
    </CustomSection>
  )
}

export default InvistaQuandoQuiser
